import {
	ActivityIndicator,
	Button,
	Dialog,
	DialogAction,
	DialogActions,
	DialogContent,
	DialogTrigger,
	Errors,
	Field,
	Form,
	Heading,
	HiddenFields,
	Input,
	Label,
	MessageBar,
	Option,
	Select,
	Tag,
	TextField,
} from '@troon/ui';
import { createAsync, useSubmission } from '@solidjs/router';
import { cachedGet, clientAction } from '@troon/api-client';
import { createEffect, createSignal, createUniqueId, For, Show, Suspense } from 'solid-js';
import dayjs from '@troon/dayjs';
import { IconInfo } from '@troon/icons/info';
import { IconUserCheck } from '@troon/icons/user-check';
import { IconCircleCheck } from '@troon/icons/circle-check';
import { Content } from '../../../../../components/main-content';
import { useCurrentFacility } from '../../../../../providers/root';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../../../components/table';
import type { ApiResponse } from '@troon/api-client';

export default function FacilityMembers() {
	const facility = useCurrentFacility();
	const members = createAsync(() => getMembers({ path: { facilityId: facility()?.facility.id ?? '' } }), {});

	return (
		<Content>
			<div class="flex justify-between">
				<Heading as="h1">Associates</Heading>

				<div>
					<Show when={facility()?.role === 'ADMIN'}>
						<InviteMember facilityId={facility()?.facility.id ?? ''} />
					</Show>
				</div>
			</div>

			<Table>
				<Thead>
					<Tr>
						<Th>Name</Th>
						<Th>Email Address</Th>
						<Th>Role</Th>
						<Th class="text-end">Actions</Th>
					</Tr>
				</Thead>
				<Tbody>
					<Suspense
						fallback={
							<Tr>
								<Td colspan={4} class="text-center">
									<ActivityIndicator />
								</Td>
							</Tr>
						}
					>
						<For
							each={members.latest?.members}
							fallback={
								<Tr>
									<Td colspan={4} class="text-center italic">
										No associates found.
									</Td>
								</Tr>
							}
						>
							{(member) => <Member {...member} facilityId={facility()?.facility.id ?? ''} />}
						</For>
					</Suspense>
				</Tbody>
			</Table>

			<Suspense>
				<Show when={(members.latest?.invites?.length ?? 0) > 0}>
					<Heading as="h2" size="h3">
						Pending Invitations
					</Heading>
					<Table>
						<Thead>
							<Tr>
								<Th>Email Address</Th>
								<Th>Expires</Th>
								<Th>Role</Th>
								<Th>Status</Th>
								<Th class="text-end">Actions</Th>
							</Tr>
						</Thead>
						<Tbody>
							<Suspense>
								<For each={members.latest?.invites?.filter((invite) => invite.state === 'PENDING')}>
									{(invite) => <Invite {...invite} facilityId={facility()?.facility.id ?? ''} />}
								</For>
							</Suspense>
						</Tbody>
					</Table>
				</Show>
			</Suspense>
		</Content>
	);
}

function Member(
	props: ApiResponse<'get', '/v0/operator/facilities/{facilityId}/members'>['members'][number] & { facilityId: string },
) {
	const [dialogOpen, setDialogOpen] = createSignal(false);
	const formId = createUniqueId();
	const submission = useSubmission(updateRole, ([data]) => data.get('__formId') === formId);

	createEffect(() => {
		if (dialogOpen() === false) {
			submission.clear();
		}
	});

	createEffect(() => {
		if (submission.result?.id) {
			setDialogOpen(false);
		}
	});

	return (
		<>
			<Tr class="group" onClick={() => setDialogOpen(true)}>
				<Td>
					{props.operator.firstName} {props.operator.lastName}
				</Td>
				<Td>{props.operator.email}</Td>
				<Td>{props.role}</Td>
				<Td class="text-end">
					<Button
						class="inline-flex"
						size="sm"
						appearance="transparent"
						onClick={() => {
							setDialogOpen(true);
						}}
						aria-controls={dialogOpen() ? props.id : undefined}
						aria-haspopup
					>
						<IconInfo />
						<span class="sr-only">More info</span>
					</Button>
				</Td>
			</Tr>
			<Dialog key="operator-member" id={props.id} open={dialogOpen()} onOpenChange={setDialogOpen}>
				<DialogContent header="Member Details" headerLevel="h2">
					<Form action={updateRole} id={formId}>
						<HiddenFields data={{ facilityId: props.facilityId, memberId: props.id }} />
						<Field name="role">
							<Label>Role</Label>
							<Select>
								<For each={['MEMBER', 'ADMIN']}>
									{(role) => (
										<Option value={role} selected={role === props.role}>
											{role}
										</Option>
									)}
								</For>
							</Select>
						</Field>

						<Errors />

						<DialogActions>
							<DialogAction type="submit">Done</DialogAction>
							<div class="w-full grow" />
							<DeleteMember {...props} />
						</DialogActions>
					</Form>
				</DialogContent>
			</Dialog>
		</>
	);
}

const updateRole = clientAction(
	'PATCH',
	'/v0/operator/facilities/{facilityId}/members/{memberId}',
	(data) => ({
		params: { path: { facilityId: data.get('facilityId') as string, memberId: data.get('memberId') as string } },
	}),
	{ revalidate: ['/v0/operator/facilities/{facilityId}/members'] },
);

function Invite(
	props: ApiResponse<'get', '/v0/operator/facilities/{facilityId}/members'>['invites'][number] & { facilityId: string },
) {
	const [dialogOpen, setDialogOpen] = createSignal(false);
	const submission = useSubmission(resendInvitation);

	createEffect(() => {
		if (!dialogOpen()) {
			submission.clear();
		}
	});

	return (
		<>
			<Tr class="group" onClick={() => setDialogOpen(true)}>
				<Td>{props.email}</Td>
				<Td>{dayjs(props.expiresAt).format('ddd, MMM D [at] h:mm A')}</Td>
				<Td>{props.role}</Td>
				<Td>
					<Tag>{props.state}</Tag>
				</Td>
				<Td class="text-end">
					<Button
						class="inline-flex"
						size="sm"
						appearance="transparent"
						onClick={() => {
							setDialogOpen(true);
						}}
						aria-controls={dialogOpen() ? props.id : undefined}
						aria-haspopup
					>
						<IconInfo />
						<span class="sr-only">More info</span>
					</Button>
				</Td>
			</Tr>
			<Dialog key="operator-member" id={props.id} open={dialogOpen()} onOpenChange={setDialogOpen}>
				<DialogContent header="Invitation details" headerLevel="h2">
					<div class="flex flex-col gap-8">
						<p>
							An invitation to <b>{props.email}</b> was sent on{' '}
							{dayjs(props.createdAt).format('ddd, MMM D [at] h:mm A')} and will expire on{' '}
							{dayjs(props.expiresAt).format('ddd, MMM D [at] h:mm A')}.
						</p>
						<Show
							when={!submission.result}
							fallback={<MessageBar appearance="brand">An invitation has been sent to {props.email}.</MessageBar>}
						>
							<Form action={resendInvitation}>
								<p>Can’t find the email? Resend the invitation.</p>
								<HiddenFields data={{ facilityId: props.facilityId, inviteId: props.id }} />
								<Button appearance="secondary" type="submit">
									Resend Invitation
								</Button>

								<Errors />
							</Form>
						</Show>

						<DialogActions>
							<DialogAction onClick={() => setDialogOpen(false)}>Done</DialogAction>
							<div class="w-full grow" />
							<DeleteInvite {...props} />
						</DialogActions>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
}

const resendInvitation = clientAction(
	'PATCH',
	'/v0/operator/facilities/{facilityId}/members/invitations/{inviteId}',
	(data) => ({
		params: { path: { facilityId: data.get('facilityId') as string, inviteId: data.get('inviteId') as string } },
	}),
);

const getMembers = cachedGet('/v0/operator/facilities/{facilityId}/members');

function InviteMember(props: { facilityId: string }) {
	const [showDialog, setShowDialog] = createSignal(false);
	const submission = useSubmission(inviteAction);

	return (
		<Dialog key="operator-invite-member" open={showDialog()} onOpenChange={setShowDialog}>
			<DialogTrigger>Invite Associate</DialogTrigger>
			<DialogContent height="fit" header="Invite Associates" headerLevel="h2">
				<Show
					when={submission.result}
					fallback={
						<Form action={inviteAction}>
							<HiddenFields data={{ facilityId: props.facilityId }} />
							<TextField name="email">
								<Label>Email address</Label>
								<Input inputMode="email" autocomplete="off" />
							</TextField>

							<Field name="role">
								<Label>Role</Label>
								<Select>
									<For each={['MEMBER', 'ADMIN']}>{(role) => <Option value={role}>{role}</Option>}</For>
								</Select>
							</Field>

							<Errors />

							<DialogActions>
								<DialogAction type="submit">Invite</DialogAction>
								<DialogAction appearance="secondary" type="button" onClick={() => setShowDialog(false)}>
									Cancel
								</DialogAction>
							</DialogActions>
						</Form>
					}
				>
					{(result) => (
						<div class="flex flex-col gap-8">
							<MessageBar icon={IconUserCheck} appearance="brand">
								<p>
									An invitation has been sent to <b>{result().email}</b>. This invitation will automatically expire on{' '}
									{dayjs(result().expiresAt).format('ddd, MMM D [at] h:mm A')}.
								</p>
							</MessageBar>

							<DialogActions>
								<DialogAction type="button" onClick={() => setShowDialog(false)}>
									Done
								</DialogAction>
								<DialogAction
									appearance="secondary"
									type="button"
									onClick={() => {
										submission.clear();
									}}
								>
									Invite more
								</DialogAction>
							</DialogActions>
						</div>
					)}
				</Show>
			</DialogContent>
		</Dialog>
	);
}

const inviteAction = clientAction(
	'POST',
	'/v0/operator/facilities/{facilityId}/members/invitations',
	(data) => ({
		params: { path: { facilityId: data.get('facilityId') as string } },
	}),
	{
		revalidate: '/v0/operator/facilities/{facilityId}/members',
	},
);

function DeleteInvite(
	props: ApiResponse<'get', '/v0/operator/facilities/{facilityId}/members'>['invites'][number] & { facilityId: string },
) {
	const [showDialog, setShowDialog] = createSignal(false);
	const submission = useSubmission(deleteInvite);

	createEffect(() => {
		if (showDialog() === false) {
			submission.clear();
		}
	});

	return (
		<Dialog key="operator-delete-invite" open={showDialog()} onOpenChange={setShowDialog}>
			<DialogTrigger class="size-fit grow-0" size="sm" appearance="transparent" action="danger">
				<p>Delete</p>
			</DialogTrigger>
			<DialogContent height="fit" header="Delete invitation?" headerLevel="h2">
				<Show
					when={submission.result}
					fallback={
						<Form action={deleteInvite}>
							<HiddenFields
								data={{
									inviteId: props.id,
									facilityId: props.facilityId,
								}}
							/>

							<p>Are you sure you want to delete this invitation?</p>

							<Errors />

							<DialogActions>
								<DialogAction type="submit" action="danger">
									Delete
								</DialogAction>
								<DialogAction onClick={() => setShowDialog(false)} appearance="secondary" type="button">
									Cancel
								</DialogAction>
							</DialogActions>
						</Form>
					}
				>
					<div class="flex flex-col gap-8">
						<MessageBar icon={IconCircleCheck} appearance="brand">
							<p>This invitation has been deleted.</p>
						</MessageBar>

						<DialogActions>
							<DialogAction type="button" onClick={() => setShowDialog(false)}>
								Done
							</DialogAction>
						</DialogActions>
					</div>
				</Show>
			</DialogContent>
		</Dialog>
	);
}

const deleteInvite = clientAction(
	'DELETE',
	'/v0/operator/facilities/{facilityId}/members/invitations/{inviteId}',
	(data) => ({
		params: { path: { facilityId: data.get('facilityId') as string, inviteId: data.get('inviteId') as string } },
	}),
	{
		revalidate: '/v0/operator/facilities/{facilityId}/members',
	},
);

function DeleteMember(
	props: ApiResponse<'get', '/v0/operator/facilities/{facilityId}/members'>['members'][number] & { facilityId: string },
) {
	const [showDialog, setShowDialog] = createSignal(false);
	const submission = useSubmission(deleteMember);

	createEffect(() => {
		if (showDialog() === false) {
			submission.clear();
		}
	});

	return (
		<Dialog key="operator-delete-member" open={showDialog()} onOpenChange={setShowDialog}>
			<DialogTrigger class="size-fit grow-0" size="sm" appearance="transparent" action="danger">
				<p>Remove </p>
			</DialogTrigger>
			<DialogContent height="fit" header={`Remove ${props.operator.firstName}?`} headerLevel="h2">
				<Show
					when={submission.result}
					fallback={
						<Form action={deleteMember}>
							<HiddenFields
								data={{
									memberId: props.id,
									facilityId: props.facilityId,
								}}
							/>

							<p>Are you sure you want to remove {props.operator.firstName}?</p>

							<Errors />

							<DialogActions>
								<DialogAction type="submit" action="danger">
									Remove
								</DialogAction>
								<DialogAction onClick={() => setShowDialog(false)} appearance="secondary" type="button">
									Cancel
								</DialogAction>
							</DialogActions>
						</Form>
					}
				>
					<div class="flex flex-col gap-8">
						<MessageBar icon={IconCircleCheck} appearance="brand">
							<p>{props.operator.firstName} has been removed.</p>
						</MessageBar>

						<DialogActions>
							<DialogAction type="button" onClick={() => setShowDialog(false)}>
								Done
							</DialogAction>
						</DialogActions>
					</div>
				</Show>
			</DialogContent>
		</Dialog>
	);
}

const deleteMember = clientAction(
	'DELETE',
	'/v0/operator/facilities/{facilityId}/members/{memberId}',
	(data) => ({
		params: { path: { facilityId: data.get('facilityId') as string, memberId: data.get('memberId') as string } },
	}),
	{
		revalidate: '/v0/operator/facilities/{facilityId}/members',
	},
);
